// 定义内容
export default {
	affiliate: {
		totleAmount: 'Cumulative payment amount:',
		username: 'username:',
		payTime: 'Payment time:',
		inputUsername: 'Input username or email or promote',
		headCard: {
			estimated_commission: 'Estimated commission',
			withdrawal_balance: 'Withdrawal Balance',
			option1_per_lead_payout: 'Option I Per Lead Payout',
			option2_per_sale_payout: 'Option II Per Sale Payout',
		},
		tables: {
			lastName: 'lastName',
			firstName: 'firstName',
			email: 'Email',
			phone: 'Phone',
			promotion_method: 'Promotion method',
			create_time: 'Create time',
			bank_number_in_us: 'Bank number in US',
			bank_number_out_us: 'Bank number out US',
			paypal: 'PayPal',
			wise: 'Wise',
			IM: 'IM',
			estimated_commission: 'Estimated commission',
			withdrawal_balance: 'Withdrawal Balance',
			operation: 'Operation',
			preLeadMethod: "Pre lead",
			preOrderMethod: "Pre order",
			payment: 'Payment',
			summaries: 'Total',
		},
		recordTables: {
			lastName: 'lastName',
			firstName: 'firstName',
			email: 'Email',
			phone: 'Phone',
			withdrawal_balance: 'Withdrawal Balance',
			create_time: 'Create time',
			operator: 'operator',
			summaries: 'Total',
		},
		form: {
			submitBtnUpdate: 'Update',
			paymenterror: "Payment error, try again later",
			paymenterror_no_bind_card: "The promoter has no bank card binding",
			paymentsuccess: "Payment success",
		},
		updatePwd: {
			tablesBtn: "Update Password",
			inputSpan: "New Password",
			inputPlaceholder: "Please enter a new password",
			submitBtnUpdate: "Update",
			inputPwdRule: 'Password cannot be blank.',
			updateSuccess: 'Password updated successfully',
			updateError: 'Password updated error, try again later',
		}
	}
};
