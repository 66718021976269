// 定义内容
export default {
	articlePage: {
		deleteById: 'Are you sure you want to delete this article? id: "{0}"',
        deleteConfirm: 'Confirm',
        deleteCancel: 'Cancel',
        deleteBoxTitle: 'Delete',
		success1: 'Deleting Succeeded',
        error1: 'Delete failed',
	},
    replyPage: {
		deleteById: 'Are you sure you want to delete this reply? id: "{0}"',
        deleteConfirm: 'Confirm',
        deleteCancel: 'Cancel',
        deleteBoxTitle: 'Delete',
		success1: 'Deleting Succeeded',
        error1: 'Delete failed',
	},
	reviewPage: {
		deleteById: 'Are you sure you want to delete this article? id: "{0}"',
        deleteConfirm: 'Confirm',
        deleteCancel: 'Cancel',
        deleteBoxTitle: 'Delete',
		success1: 'Deleting Succeeded',
        error1: 'Delete failed',
		approveById: 'Are you sure you want to article this approve? id: "{0}"',
        approveConfirm: 'Confirm',
        approveCancel: 'Cancel',
        approveBoxTitle: 'Approve',
		success2: 'Approve Succeeded',
        error2: 'Approve failed',
	},
};
