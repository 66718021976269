import axios from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Session } from '/@/utils/storage';

console.log(import.meta.env);


// 配置新建一个 axios 实例
const service = axios.create({
    baseURL: import.meta.env.MODE==="development"? "/":  import.meta.env.VITE_API_URL as any,
    timeout: 50000,
    headers: { 'Content-Type': 'application/json;charset=utf-8' },
});

// 添加请求拦截器
service.interceptors.request.use(
    (config) => {
        // 在发送请求之前做些什么 token
        if (Session.get('token')) {
            config.headers.common['Authorization'] = `${Session.get('token')}`;
        }
        return config;
    },
    (error) => {
        console.log(error)
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
service.interceptors.response.use(
    (response) => {
        // 对响应数据做点什么
        const res = response.data;
        // const res = response.data ? response.data : response;
        if (res.code && res.code !== '1') {
            // `token` 过期或者账号已在别处登录
            if (res.code === 401 || res.code === 4001 || res.code === 'A4037') {
                Session.clear(); // 清除浏览器全部临时缓存
                ElMessageBox.alert('Login timeout, please login again', 'Tips', {})
                    .then(() => {
                        window.location.href = import.meta.env.VITE_PUBLIC_PATH + 'index.html'; // 去登录页
                    })
                    .catch(() => { });
            } else if (res.code === 'A403') {
                Session.clear(); // 清除浏览器全部临时缓存
                ElMessageBox.alert('No permission, please contact the administrator', 'Tips', {})
                    .then(() => {
                        window.location.href = import.meta.env.VITE_PUBLIC_PATH + 'index.html'; // 去登录页
                    })
                    .catch(() => { });
            }
            return Promise.reject(response);
        } else {
            return response.data;
        }
    },
    (error) => {
        // 对响应错误做点什么
        if (error.message.indexOf('timeout') != -1) {
            ElMessage.error('Network Timeout');
        } else if (error.message == 'Network Error') {
            ElMessage.error('Network connection error');
        } else {
            if (error.response.data) ElMessage.error(error.response.statusText);
            else ElMessage.error('接口路径找不到');
        }
        return Promise.reject(error);
    }
);

// 导出 axios 实例
export default service;
