import request from '/@/utils/request';
import qs from "qs";

/**
 * 重置密码
 * @param params 
 * @returns 
 */
 export function resetPassword(params: Object) {
    return request({
        url: '/admin/agent/resetPassword',
        method: 'put',
        params
    });
}

/**
 * 获得代理列表
 * @param type 
 * @param pageIndex 
 * @param pageSize 
 * @returns 
 */
 export function getAgent() {
    return request({
        url: '/admin/agent/getAgent',
        method: 'get',
    });
}

/**
 * 获得在线用户列表
 * @param type 
 * @param pageIndex 
 * @param pageSize 
 * @returns 
 */
 export function getOnlineAnchor(pageIndex: number, pageSize: number) {
    let params = {
        pageIndex,
        pageSize
    }
    return request({
        url: '/admin/agent/getOnlineAnchor',
        method: 'get',
        params
    });
}

/**
 * 获取主播呼叫汇总数据
 * @param type 
 * @param pageIndex 
 * @param pageSize 
 * @returns 
 */
 export function getStarCallStatistics(time: string, pageIndex: number, pageSize: number) {
    let params = {
        time,
        pageIndex,
        pageSize
    }
    return request({
        url: '/admin/agent/getStarCallStatistics',
        method: 'get',
        params
    });
}

/**
 * 获取主播呼叫汇总数据
 * @param type 
 * @param pageIndex 
 * @param pageSize 
 * @returns 
 */
 export function getStarCallStatistics2(username: string, startTime: string, endTime: string,
                                        pageIndex: number, pageSize: number) {
    let params = {
        username,
        startTime,
        endTime,
        pageIndex,
        pageSize
    }
    return request({
        url: '/admin/agent/getStarCallStatistics2',
        method: 'get',
        params
    });
}



/**
 * 获得玩家的汇总数据
 * @param params 
 * @returns 
 */
 export function getUserDataStatisticCollectDto(params?: object) {
    return request({
        url: '/admin/agent/getUserDataStatisticCollectDto',
        method: 'get',
        params
    });
}


/**
 * 获得主播提现申请记录
 * @param params 
 * @returns 
 */
 export function getStarWithdrawApplyLogDtoByAuditNotPass(params?: object) {
    return request({
        url: '/admin/agent/getStarWithdrawApplyLogDtoByAuditNotPass',
        method: 'get',
        params
    });
}

/**
 * 获得主播已提现记录
 * @param params 
 * @returns 
 */
 export function getStarWithdrawApplyLogDtoByAuditPass(params?: object) {
    return request({
        url: '/admin/agent/getStarWithdrawApplyLogDtoByAuditPass',
        method: 'get',
        params
    });
}

/**
 * 添加payment信息
 * @param params 
 * @returns 
 */
export function addPayment(params: Object) {
    return request({
        url: '/admin/agent/payment',
        method: 'put',
        data: qs.parse(params)
    });
}

/**
 * 更新payment信息
 * @param params 
 * @returns 
 */
export function updatePayment(params: Object) {
    return request({
        url: '/admin/agent/payment',
        method: 'post',
        data: qs.parse(params)
    });
}


/**
 * 删除payment信息
 * @param params 
 * @returns 
 */
export function deletePayment(id: number) {
    return request({
        url: '/admin/agent/payment?id=' + id,
        method: 'delete'
    });
}

/**
 * 获得代理Payment列表
 * @param type 
 * @param pageIndex 
 * @param pageSize 
 * @returns 
 */
 export function getPayment(agent: string) {
    return request({
        url: '/admin/agent/payment?agent=' + agent,
        method: 'get',
    });
}


/**
 * 代理列表
 * @param params 
 * @returns 
 */
export function getAgentTree() {
    return request({
        url: '/admin/agent/getAgentTree',
        method: 'get',
    });
}

/**
 * 更新代理层级
 * @param params 
 * @returns 
 */
export function updateAgentLevel(params: Object) {
    return request({
        url: '/admin/agent/updateAgentLevel',
        method: 'post',
        data: qs.parse(params)
    });
}