// 定义内容
// ~~~~~~~~~~~~~~~~ hang ~~~~~~~~~~~~~~
export default {
    setting: {
        btn1: '刷新缓存',
        success1: '刷新缓存成功',
        error1: '刷新缓存失败',
        error2: ' 接口刷新缓存失败',
    }
};
