// 定义内容
export default {
    router: {
        home: '首页',
        system: '系统管理',
        systemMenu: '菜单管理',
        systemUser: '管理员用户',
        agentUser: '经纪人用户',
        systemRole: '角色管理',
        systemSetting: '系统设置',
        user: '用户',
        userNewProfiles: '老用户审核',
        userNewStarProfiles: '新主播审核',
        userOldProfiles: '老主播审核',
        userVideoProfiles: '视频审核',
        userPremiumProfiles: '支付审核',
        userSearchProfiles: '搜索审核',
        userProfilesStar: '主播列表',
        profilesReal: '真实主播',
        userReportProfiles: '举报审核',
        userCallCommentsProfiles: '通话评价',
        financial: '财务报表',
        userVerity: '用户认证',
        allProfiles: '所有用户',
        sendPushMsg: '发送推送消息',
        limits: '权限管理',
        limitsFrontEnd: '前端控制',
        limitsFrontEndPage: '页面权限',
        limitsFrontEndBtn: '按钮权限',
        limitsBackEnd: '后端控制',
        limitsBackEndEndPage: '页面权限',
        email: '邮件',
        emailInbox: '收件箱',
        emailSent: '发件箱',
        emailWrite: '写信',
        goods: '商品',
        menu: '菜单嵌套',
        menu1: '菜单1',
        menu11: '菜单11',
        menu12: '菜单12',
        menu121: '菜单121',
        menu122: '菜单122',
        menu13: '菜单13',
        menu2: '菜单2',
        funIndex: '功能',
        funTagsView: 'tagsView 操作',
        funCountup: 'countup 数字滚动',
        funEchartsTree: 'echartsTree 树图',
        funSelector: '图标选择器',
        funNoticeBar: '滚动通知栏',
        funWangEditor: 'wangEditor 编辑器',
        funCropper: 'cropper 图片裁剪',
        funQrcode: 'qrcode 二维码生成',
        funEchartsMap: '地理坐标/地图',
        funPrintJs: '页面打印',
        funClipboard: '复制剪切',
        funScreenShort: 'web端自定义截屏',
        funGridLayout: '拖拽布局',
        funSplitpanes: '窗格拆分器',
        funDragVerify: '验证器',
        pagesIndex: '页面',
        pagesFiltering: '过滤筛选组件',
        pagesFilteringDetails: '过滤筛选组件详情',
        pagesFilteringDetails1: '过滤筛选组件详情111',
        pagesIocnfont: 'iconfont 字体图标',
        pagesElement: 'element 字体图标',
        pagesAwesome: 'awesome 字体图标',
        pagesCityLinkage: '城市多级联动',
        pagesFormAdapt: '表单自适应',
        pagesFormI18n: '表单国际化',
        pagesFormRules: '多表单验证',
        pagesDynamicForm: '动态复杂表单',
        pagesWorkflow: '工作流',
        pagesListAdapt: '列表自适应',
        pagesWaterfall: '瀑布屏',
        pagesSteps: '步骤条',
        pagesPreview: '大图预览',
        pagesWaves: '波浪效果',
        pagesTree: '树形改表格',
        pagesDrag: '拖动指令',
        pagesLazyImg: '图片懒加载',
        paramsIndex: '路由参数',
        paramsCommon: '普通路由',
        paramsDynamic: '动态路由',
        paramsCommonDetails: '普通路由详情',
        paramsDynamicDetails: '动态路由详情',
        chartIndex: '大数据图表',
        visualizingIndex: '数据可视化',
        visualizingLinkDemo1: '数据可视化演示1',
        visualizingLinkDemo2: '数据可视化演示2',
        personal: '个人中心',
        tools: '工具类集合',
        layoutLinkView: '外链',
        layoutIfameView: '内嵌 iframe',
        affiliate:"推广",
        affiliateReport: '报表',
        affiliatePayLog: '打款记录',
        promoterList: '推广用户',
        anchor: '主播',
        star: '主播',
        starReport: '报表',
        starWithdraw: '提现申请',
        starWithdrawHistory: '提现历史',
        starMonitor: '云监控',
        agentWithdrawHistory: '提现历史',
        onlineUser: '在线会员',
        profilesAgent: '代理的会员',
        registerAnchor: '添加虚拟主播',
        reviewConfig: '审核配置',
        messageList: '消息列表',
        article: '动态',
        articleList: '列表',
        articleReview: '审核',
        articleReport: '举报',
        aso: 'Aso',
        agent: '经纪人',
        agentList: '经纪人列表',
        agentOnline: '在线列表',
        agentHost: '报表',
        agentWithdraw: '提现申请',
        agentStar: '主播数据',
        starPerformance: '主播业绩',
        searchConfig: 'Search页配置',
    },
    staticRoutes: {
        signIn: '登录',
        notFound: '找不到此页面',
        noPower: '没有权限',
    },
    user: {
        title0: '组件大小',
        title1: '语言切换',
        title2: '菜单搜索',
        title3: '布局配置',
        title4: '消息',
        title5: '开全屏',
        title6: '关全屏',
        dropdownDefault: '默认',
        dropdownMedium: '中等',
        dropdownSmall: '小型',
        dropdownMini: '超小',
        dropdown1: '首页',
        dropdown2: '个人中心',
        dropdown3: '404',
        dropdown4: '401',
        dropdown5: '退出登录',
        dropdown6: '代码仓库',
        searchPlaceholder: '菜单搜索：支持中文、路由路径',
        newTitle: '通知',
        newBtn: '全部已读',
        newGo: '前往通知中心',
        newDesc: '暂无通知',
        logOutTitle: '提示',
        logOutMessage: '此操作将退出登录, 是否继续?',
        logOutConfirm: '确定',
        logOutCancel: '取消',
        logOutExit: '退出中',
        logOutSuccess: '安全退出成功！',
    },
    tagsView: {
        refresh: '刷新',
        close: '关闭',
        closeOther: '关闭其它',
        closeAll: '全部关闭',
        fullscreen: '当前页全屏',
        closeFullscreen: '关闭全屏',
    },
    notFound: {
        foundTitle: '地址输入错误，请重新输入地址~',
        foundMsg: '您可以先检查网址，然后重新输入或给我们反馈问题。',
        foundBtn: '返回首页',
    },
    noAccess: {
        accessTitle: '您未被授权，没有操作权限~',
        accessMsg: '联系方式：加QQ群探讨 665452019',
        accessBtn: '重新授权',
    },
    layout: {
        configTitle: '布局配置',
        oneTitle: '全局主题',
        twoTitle: '菜单 / 顶栏',
        twoTopBar: '顶栏背景',
        twoMenuBar: '菜单背景',
        twoColumnsMenuBar: '分栏菜单背景',
        twoTopBarColor: '顶栏默认字体颜色',
        twoMenuBarColor: '菜单默认字体颜色',
        twoColumnsMenuBarColor: '分栏菜单默认字体颜色',
        twoIsTopBarColorGradual: '顶栏背景渐变',
        twoIsMenuBarColorGradual: '菜单背景渐变',
        twoIsColumnsMenuBarColorGradual: '分栏菜单背景渐变',
        twoIsMenuBarColorHighlight: '菜单字体背景高亮',
        threeTitle: '界面设置',
        threeIsCollapse: '菜单水平折叠',
        threeIsUniqueOpened: '菜单手风琴',
        threeIsFixedHeader: '固定 Header',
        threeIsClassicSplitMenu: '经典布局分割菜单',
        threeIsLockScreen: '开启锁屏',
        threeLockScreenTime: '自动锁屏(s/秒)',
        fourTitle: '界面显示',
        fourIsShowLogo: '侧边栏 Logo',
        fourIsBreadcrumb: '开启 Breadcrumb',
        fourIsBreadcrumbIcon: '开启 Breadcrumb 图标',
        fourIsTagsview: '开启 Tagsview',
        fourIsTagsviewIcon: '开启 Tagsview 图标',
        fourIsCacheTagsView: '开启 TagsView 缓存',
        fourIsSortableTagsView: '开启 TagsView 拖拽',
        fourIsShareTagsView: '开启 TagsView 共用',
        fourIsFooter: '开启 Footer',
        fourIsGrayscale: '灰色模式',
        fourIsInvert: '色弱模式',
        fourIsDark: '深色模式',
        fourIsWartermark: '开启水印',
        fourWartermarkText: '水印文案',
        fiveTitle: '其它设置',
        fiveTagsStyle: 'Tagsview 风格',
        fiveAnimation: '主页面切换动画',
        fiveColumnsAsideStyle: '分栏高亮风格',
        fiveColumnsAsideLayout: '分栏布局风格',
        sixTitle: '布局切换',
        sixDefaults: '默认',
        sixClassic: '经典',
        sixTransverse: '横向',
        sixColumns: '分栏',
        tipText: '点击下方按钮，复制布局配置去 `src/store/modules/themeConfig.ts` 中修改。',
        copyText: '一键复制配置',
        resetText: '一键恢复默认',
        copyTextSuccess: '复制成功！',
        copyTextError: '复制失败！',
    },
};
