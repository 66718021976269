import { ElMessage } from "element-plus";
import { getAgentArr } from "../api/user/profiles";
import { Session } from "./storage";
import { getAgentTree } from "../api/agent";


export function loadAgentArrData() {
    //加载代理列表
    getAgentArr().then((response: any) => {
        if (response.success) {
            let agentData = [];
            if(response.data != null && response.data.length > 0) {
                for (const i in response.data) {
                    agentData.push(response.data[i]);
                }
            }
			Session.set('agent_arr', agentData)
        } else {
            ElMessage.error('Failed to get agent arr');
        }
    })
    .catch(() => {
        ElMessage.error('Failed to get agent arr');
    });
    //加载代理列表
    getAgentTree().then((response: any) => {
        if (response.success) {
            let agentData = [];
            if(response.data != null && response.data.length > 0) {
                for (const i in response.data) {
                    agentData.push(response.data[i]);
                }
            }
			Session.set('agent_arr_tree', agentData)
        } else {
            ElMessage.error('Failed to get agent tree arr');
        }
    })
    .catch(() => {
        ElMessage.error('Failed to get agent tree arr');
    });
}


export function getAgentArrData(): any[] {
    let agentArr: any[] = [];
    let v = Session.get('agent_arr')
    if(v != null && v != undefined) {
        agentArr = v
    }

    return agentArr;
}

export function getAgentArrDataTree(): any[] {
    let agentArr: any[] = [];
    let v = Session.get('agent_arr_tree')
    if(v != null && v != undefined) {
        agentArr = v
    }

    return agentArr;
}