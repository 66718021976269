// 定义内容
export default {
	audit: {
		man: '男',
		woman: '女',
		other: '其他',
		success1: '更新用户审核数据成功',
		success2: '更新密码成功',
		success3: '添加vip成功',
		success4: '删除vip成功',
		success5: '认证处理成功',
		success6: '添加视频时长成功',
		success7: '删除视频时长成功',
		success8: '增加用户金币成功',
		success9: '重置用户金币成功',
		success10: '修改主播收费成功',
		success11: '修改主播提成成功',
		success12: '修改主播礼物提成成功',
		success13: '修改主用户等级成功',
		error1: '用户名不存在',
		error2: '更新用户审核数据错误',
		error3: '更新密码失败',
		error4: '添加vip失败',
		error5: '删除vip失败',
		error6: '获取用户认证数据失败',
		error7: '提交认证失败',
		error8: '{0} 用户处理失败',
		error9: '添加视频时长失败',
		error10: '删除视频时长失败',
		error11: '增加用户金币失败',
		error12: '重置用户金币失败',
		error13: '修改主播收费失败',
		error14: '修改主播提成失败',
		error15: '修改主播礼物提成失败',
		error16: '修改用户等级失败',
		message1: '请输入姓名',
		message2: '请输入昵称',
		message3: '请输入性别',
		message4: '关于我',
		message5: '用户没有上传',
		message6: '点击查看大图预览',
		message7: '修改密码',
		message8: '请输入认证失败原因',
		message9: '请输入邮箱',
		message10: '个性签名',
		submitConfirm: {
			msg: '{0} 用户未填写失败原因，确定提交？',
			title: '提示',
			confirmBtn: '确定',
			cancelBtn: '取消'
		}
	}
};
