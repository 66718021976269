// 定义内容
export default {
	home: {
		table: {
			th1: '日期',
			th2: '用户',
			th3: '总金额',
			th4: '男',
			th5: '女',
			th6: '活跃',
			th7: '验证',
			th8: '退费',
		},
		totalUser: '总注册数',
		todayIncome: '今日新增',
		online: '在线用户',
		dailyBtn: '每日',
		weeklyBtn: '每周',
		monthlyBtn: '每月',
		yearlyBtn: '每年',
		title1: '注册用户',
		title2: '国家排行',
		title3: '销售数据',
		title4: '销售报表',
		number: '数量',
		error1: '获得注册数据失败',
		error2: '获取充值统计数据失败',
	}
};
