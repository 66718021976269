// 定义内容
export default {
	email: {
		markRead: "mark read",
		emailStatueAll: "All",
		emailStatueRead: "Read",
		emailStatueUnread: "Unread",
		emailReply: 'Reply',
		emailTitle: 'Email details',
		send: {
			addressee: 'Addressee',
			sender: 'sender',
			time: 'time',
			subject: 'Subject',
			content: 'Content',
			sendBtn: 'Send',
			cancelBtn: 'Cancel',
			emailPrompt: 'Please enter email address',
			contentPrompt: 'Please enter the email content',
			emailSendSucceed: 'Email sent successfully',
			emailSendError: 'Email sending failed',
			emailFormatError: 'Please enter the correct email address',
		}
	}
};
