import { Module } from 'vuex';
import { MenuBadgeState, RootStateTypes } from '/@/store/interface/index';
import { getBadges } from '/@/api/user';
// 菜单徽章
const routesListModule: Module<MenuBadgeState, RootStateTypes> = {
    namespaced: true,
    state: {
        profilesMap: new Map([
            ['profilesNew', 0],
            ['profilesNewStar', 0],
            ['profilesOld', 0],
            ['profilesPremium', 0],
            ['profilesVideo', 0],
            ['emailInbox', 0],
            ['userVerity', 0],
            ['profilesReport', 0],
            ['starWithdraw', 0],
            ['profilesCallComments', 0]
        ]),
        // 菜单徽章定时刷新的时间 单位/秒
        menuBadgeTime: 20
    },
    mutations: {
        // 添加菜单徽章
        updateSingleNumber(state: any, data: any) {
            let name = data.name;
            // 如果类型是1：添加指定数字；2：从新设置成指定数字
            if (data.type == 1) {
                let newProfilesNum = state.profilesMap.get(name);
                newProfilesNum += data.num;
                state.profilesMap.set(name, newProfilesNum);
            } else {
                state.profilesMap.set(name, data.num);
            }
        },

        updateNumber(state: any, data: any) {
            for (const key in data) {
                state.profilesMap.set(key, data[key]);
            }
        },
    },
    actions: {
        async updateEmailInbox({ commit }, data: number) {
            commit('updateSingleNumber', { name: 'emailInbox', type: 1, num: data });
        },

        /**
         * 更新收件箱的数量
         * @param param0 
         * @param data 
         */
        async getBadgesData({ commit }, names: []) {
            getBadges(names).then((result) => {
                commit('updateNumber', result.data);
            });
        }

    },
};

export default routesListModule;
