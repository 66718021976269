// 定义内容
// ~~~~~~~~~~~~~~~~ hang ~~~~~~~~~~~~~~
export default {
    rolePage: {
        roleTitle: 'Role management',
        menuTitle: 'Menu',
        menuUpdateBtn: 'Submit',
        tables: {
            roleName: 'Role Name',
            roleCode: 'Role Code',
            operation: 'Operation',
            add: 'New',
            sort: 'Sorted',
            status: 'Status',
        },
        radioStop: 'Stop',
        radioOpen: 'Start',
        dialog: {
            addTitle: 'New Role',
            updateTitle: 'Update Role',
            addBtn: 'New',
            updateBtn: 'Update',
            cancelBtn: 'Cancel',
        },
        deleteBoxTitle: 'Delete',
        deleteCodeHint: 'The admin and root roles cannot be deleted',
        deleteMsg: 'Confirm deleting the "{0}" role',
        deleteConfirm: 'Confirm',
        deleteCancel: 'Cancel',
        addRoleSucceedPrompt: 'Adding a Role Succeeded',
        updateRoleSucceedPrompt: 'Role Modification Succeeded',
        placeholder1: 'Please enter the role name',
        placeholder2: 'Please enter the role code',
        placeholder3: 'Please select a role',
        placeholder4: 'No menu available at the moment',
        placeholder5: 'Data is being loaded and cannot be committed...',
        placeholder6: 'Please select a role',
        placeholder7: 'Please select menu',
        placeholder8: 'Menu data is not modified',
        success1: 'Deleting a Role Succeeded',
        success2: 'Update successfully',
        error1: 'Please fill in the complete information',
        error2: 'Delete failed, the role does not exist, please refresh',
        error3: 'Failed to Delete a role',
        error4: 'Failed to obtain the menu information of the role. Procedure',
    }
};
