import request from '/@/utils/request';
import qs from "qs";

/**
 * 用户登录
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function signIn(params: object) {
    return request({
        url: '/user/signIn',
        method: 'post',
        data: params,
    });
}

/**
 * 用户退出登录
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function signOut(params: object) {
    return request({
        url: '/user/signOut',
        method: 'post',
        data: params,
    });
}

export function getKaptcha(){
    return request({
        url: '/auth/kaptcha/kaptcha.jpg',
        method: 'get',
    })
}

// ~~~~~~~~~~~~~~~~ hang ~~~~~~~~~~~~~~
/**
 * 权限验证
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function oauthToken(params: object) {
    return request({
        url: '/auth/oauth/token',
        method: 'post',
        headers: {
            'Accept': '*/*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic c3BkLWFkbWluOkowMjhLYyRGV3M3a1Y5dTY='
        },
        data: qs.stringify(params),
    });
}
