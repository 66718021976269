import { ElMessage } from "element-plus";
import { platform } from "os";
import { ref } from "vue";
import { changeReviewRegisterToA, getEffectivePlatform, getPlatformAlias } from "../api";
import { Session } from "./storage";

export enum PlatformType {
    Ios = 'Ios',
    Android = 'Android',
}

export const PlatformOptions = [
    {
        value: PlatformType.Ios,
        label: PlatformType.Ios,
    },
    {
        value: PlatformType.Android,
        label: PlatformType.Android,
    },
]

export function getPlatformType(value: number){
    if(value === 1){
        return PlatformType.Android;
    }else if(value === 2){
        return PlatformType.Ios;
    }

    return 'Other';
}
export enum AppPlatform {
    soloer = "soloer",
    farmers = 'farmers',
    farmersuk = 'farmersuk',
    cosplay = 'cosplay',
    moto = 'moto',
    reader = 'reader',
    p6 = 'food',
    p7 = 'hikChat',
    p8 = 'GameTalk',
    p9 = 'p9',
    p10 = 'p10',
    p11 = 'p11',
    p12 = 'p12',
    p13 = 'p13',
    p14 = 'p14',
    p15 = 'p15',
    p16 = 'p16',
    p17 = 'p17',
    p18 = 'p18',
    p19 = 'p19',
    p20 = 'p20',
    p21 = 'p21',
    p22 = 'p22',
    p23 = 'p23',
    p24 = 'p24',
    p25 = 'p25',
    p26 = 'p26',
    p27 = 'p27',
    p28 = 'p28',
    p29 = 'p29',
    p30 = 'p30',
    p31 = 'p31',
    p32 = 'p32',
    p33 = 'p33',
    p34 = 'p34',
    p35 = 'p35',
    p36 = 'p36',
    p37 = 'p37',
    p38 = 'p38',
    p39 = 'p39',
    p40 = 'p40',
    p41 = 'p41',
    p42 = 'p42',
    p43 = 'p43',
    p44 = 'p44',
    p45 = 'p45',
    p46 = 'p46',
    p47 = 'p47',
    p48 = 'p48',
    p49 = 'p49',
    p50 = 'p50',
    p51 = 'p51',
    p52 = 'p52',
    p53 = 'p53',
    p54 = 'p54',
    p55 = 'p55',
    p56 = 'p56',
    p57 = 'p57',
    p58 = 'p58',
    p59 = 'p59',
    p60 = 'p60',
    p61 = 'p61',
    p62 = 'p62',
    p63 = 'p63',
    p64 = 'p64',
    p65 = 'p65',
    p66 = 'p66',
    p67 = 'p67',
    p68 = 'p68',
    p69 = 'p69',
    p70 = 'p70',
    p71 = 'p71',
    p72 = 'p72',
    p73 = 'p73',
    p74 = 'p74',
    p75 = 'p75',
    p76 = 'p76',
    p77 = 'p77',
    p78 = 'p78',
    p79 = 'p79',
    p80 = 'p80',
    p81 = 'p81',
    p82 = 'p82',
    p83 = 'p83',
    p84 = 'p84',
    p85 = 'p85',
    p86 = 'p86',
    p87 = 'p87',
    p88 = 'p88',
    p89 = 'p89',
    p90 = 'p90',
    p91 = 'p91',
    p92 = 'p92',
    p93 = 'p93',
    p94 = 'p94',
    p95 = 'p95',
    p96 = 'p96',
    p97 = 'p97',
    p98 = 'p98',
    p99 = 'p99',
    p100 = 'p100',
    p101 = 'p101',
    p102 = 'p102',
    p103 = 'p103',
    p104 = 'p104',
    p105 = 'p105',
    p106 = 'p106',
    p107 = 'p107',
    p108 = 'p108',
    p109 = 'p109',
    p110 = 'p110',
    p111 = 'p111',
    p112 = 'p112',
    p113 = 'p113',
    p114 = 'p114',
    p115 = 'p115',
    p116 = 'p116',
    p117 = 'p117',
    p118 = 'p118',
    p119 = 'p119',
    p120 = 'p120',
    p121 = 'p121',
    p122 = 'p122',
    p123 = 'p123',
    p124 = 'p124',
    p125 = 'p125',
    p126 = 'p126',
    p127 = 'p127',
    p128 = 'p128',
    p129 = 'p129',
    p130 = 'p130',
    p131 = 'p131',
    p132 = 'p132',
    p133 = 'p133',
    p134 = 'p134',
    p135 = 'p135',
    p136 = 'p136',
    p137 = 'p137',
    p138 = 'p138',
    p139 = 'p139',
    p140 = 'p140',
    p141 = 'p141',
    p142 = 'p142',
    p143 = 'p143',
    p144 = 'p144',
    p145 = 'p145',
    p146 = 'p146',
    p147 = 'p147',
    p148 = 'p148',
    p149 = 'p149',
    p150 = 'p150',
    p151 = 'p151',
    p152 = 'p152',
    p153 = 'p153',
    p154 = 'p154',
    p155 = 'p155',
    p156 = 'p156',
    p157 = 'p157',
    p158 = 'p158',
    p159 = 'p159',
    p160 = 'p160',
    p161 = 'p161',
    p162 = 'p162',
    p163 = 'p163',
    p164 = 'p164',
    p165 = 'p165',
    p166 = 'p166',
    p167 = 'p167',
    p168 = 'p168',
    p169 = 'p169',
    p170 = 'p170',
    p171 = 'p171',
    p172 = 'p172',
    p173 = 'p173',
    p174 = 'p174',
    p175 = 'p175',
    p176 = 'p176',
    p177 = 'p177',
    p178 = 'p178',
    p179 = 'p179',
    p180 = 'p180',
    p181 = 'p181',
    p182 = 'p182',
    p183 = 'p183',
    p184 = 'p184',
    p185 = 'p185',
    p186 = 'p186',
    p187 = 'p187',
    p188 = 'p188',
    p189 = 'p189',
    p190 = 'p190',
    p191 = 'p191',
    p192 = 'p192',
    p193 = 'p193',
    p194 = 'p194',
    p195 = 'p195',
    p196 = 'p196',
    p197 = 'p197',
    p198 = 'p198',
    p199 = 'p199',
    p200 = 'p200',
    p201 = 'p201',
    p202 = 'p202',
    p203 = 'p203',
    p204 = 'p204',
    p205 = 'p205',
    p206 = 'p206',
    p207 = 'p207',
    p208 = 'p208',
    p209 = 'p209',
    p210 = 'p210',
    p211 = 'p211',
    p212 = 'p212',
    p213 = 'p213',
    p214 = 'p214',
    p215 = 'p215',
    p216 = 'p216',
    p217 = 'p217',
    p218 = 'p218',
    p219 = 'p219',
    p220 = 'p220',
    p221 = 'p221',
    p222 = 'p222',
    p223 = 'p223',
    p224 = 'p224',
    p225 = 'p225',
    p226 = 'p226',
    p227 = 'p227',
    p228 = 'p228',
    p229 = 'p229',
    p230 = 'p230',
    p231 = 'p231',
    p232 = 'p232',
    p233 = 'p233',
    p234 = 'p234',
    p235 = 'p235',
    p236 = 'p236',
    p237 = 'p237',
    p238 = 'p238',
    p239 = 'p239',
    p240 = 'p240',
    p241 = 'p241',
    p242 = 'p242',
    p243 = 'p243',
    p244 = 'p244',
    p245 = 'p245',
    p246 = 'p246',
    p247 = 'p247',
    p248 = 'p248',
    p249 = 'p249',
    p250 = 'p250',
    p251 = 'p251',
    p252 = 'p252',
    p253 = 'p253',
    p254 = 'p254',
    p255 = 'p255',
    p256 = 'p256',
    p257 = 'p257',
    p258 = 'p258',
    p259 = 'p259',
    p260 = 'p260',
    p261 = 'p261',
    p262 = 'p262',
    p263 = 'p263',
    p264 = 'p264',
    p265 = 'p265',
    p266 = 'p266',
    p267 = 'p267',
    p268 = 'p268',
    p269 = 'p269',
    p270 = 'p270',
    p271 = 'p271',
    p272 = 'p272',
    p273 = 'p273',
    p274 = 'p274',
    p275 = 'p275',
    p276 = 'p276',
    p277 = 'p277',
    p278 = 'p278',
    p279 = 'p279',
    p280 = 'p280',
    p281 = 'p281',
    p282 = 'p282',
    p283 = 'p283',
    p284 = 'p284',
    p285 = 'p285',
    p286 = 'p286',
    p287 = 'p287',
    p288 = 'p288',
    p289 = 'p289',
    p290 = 'p290',
    p291 = 'p291',
    p292 = 'p292',
    p293 = 'p293',
    p294 = 'p294',
    p295 = 'p295',
    p296 = 'p296',
    p297 = 'p297',
    p298 = 'p298',
    p299 = 'p299',
    p300 = 'p300',
    p301 = 'p301',
    p302 = 'p302',
    p303 = 'p303',
    p304 = 'p304',
    p305 = 'p305',
    p306 = 'p306',
    p307 = 'p307',
    p308 = 'p308',
    p309 = 'p309',
    p310 = 'p310',
    p311 = 'p311',
    p312 = 'p312',
    p313 = 'p313',
    p314 = 'p314',
    p315 = 'p315',
    p316 = 'p316',
    p317 = 'p317',
    p318 = 'p318',
    p319 = 'p319',
    p320 = 'p320',
    p321 = 'p321',
    p322 = 'p322',
    p323 = 'p323',
    p324 = 'p324',
    p325 = 'p325',
    p326 = 'p326',
    p327 = 'p327',
    p328 = 'p328',
    p329 = 'p329',
    p330 = 'p330',
    p331 = 'p331',
    p332 = 'p332',
    p333 = 'p333',
    p334 = 'p334',
    p335 = 'p335',
    p336 = 'p336',
    p337 = 'p337',
    p338 = 'p338',
    p339 = 'p339',
    p340 = 'p340',
    p341 = 'p341',
    p342 = 'p342',
    p343 = 'p343',
    p344 = 'p344',
    p345 = 'p345',
    p346 = 'p346',
    p347 = 'p347',
    p348 = 'p348',
    p349 = 'p349',
    p350 = 'p350',
    p351 = 'p351',
    p352 = 'p352',
    p353 = 'p353',
    p354 = 'p354',
    p355 = 'p355',
    p356 = 'p356',
    p357 = 'p357',
    p358 = 'p358',
    p359 = 'p359',
    p360 = 'p360',
    p361 = 'p361',
    p362 = 'p362',
    p363 = 'p363',
    p364 = 'p364',
    p365 = 'p365',
    p366 = 'p366',
    p367 = 'p367',
    p368 = 'p368',
    p369 = 'p369',
    p370 = 'p370',
    p371 = 'p371',
    p372 = 'p372',
    p373 = 'p373',
    p374 = 'p374',
    p375 = 'p375',
    p376 = 'p376',
    p377 = 'p377',
    p378 = 'p378',
    p379 = 'p379',
    p380 = 'p380',
    p381 = 'p381',
    p382 = 'p382',
    p383 = 'p383',
    p384 = 'p384',
    p385 = 'p385',
    p386 = 'p386',
    p387 = 'p387',
    p388 = 'p388',
    p389 = 'p389',
    p390 = 'p390',
    p391 = 'p391',
    p392 = 'p392',
    p393 = 'p393',
    p394 = 'p394',
    p395 = 'p395',
    p396 = 'p396',
    p397 = 'p397',
    p398 = 'p398',
    p399 = 'p399',
    p400 = 'p400',
    p401 = 'p401',
    p402 = 'p402',
    p403 = 'p403',
    p404 = 'p404',
    p405 = 'p405',
    p406 = 'p406',
    p407 = 'p407',
    p408 = 'p408',
    p409 = 'p409',
    p410 = 'p410',
    p411 = 'p411',
    p412 = 'p412',
    p413 = 'p413',
    p414 = 'p414',
    p415 = 'p415',
    p416 = 'p416',
    p417 = 'p417',
    p418 = 'p418',
    p419 = 'p419',
    p420 = 'p420',
    p421 = 'p421',
    p422 = 'p422',
    p423 = 'p423',
    p424 = 'p424',
    p425 = 'p425',
    p426 = 'p426',
    p427 = 'p427',
    p428 = 'p428',
    p429 = 'p429',
    p430 = 'p430',
    p431 = 'p431',
    p432 = 'p432',
    p433 = 'p433',
    p434 = 'p434',
    p435 = 'p435',
    p436 = 'p436',
    p437 = 'p437',
    p438 = 'p438',
    p439 = 'p439',
    p440 = 'p440',
    p441 = 'p441',
    p442 = 'p442',
    p443 = 'p443',
    p444 = 'p444',
    p445 = 'p445',
    p446 = 'p446',
    p447 = 'p447',
    p448 = 'p448',
    p449 = 'p449',
    p450 = 'p450',
    p451 = 'p451',
    p452 = 'p452',
    p453 = 'p453',
    p454 = 'p454',
    p455 = 'p455',
    p456 = 'p456',
    p457 = 'p457',
    p458 = 'p458',
    p459 = 'p459',
    p460 = 'p460',
    p461 = 'p461',
    p462 = 'p462',
    p463 = 'p463',
    p464 = 'p464',
    p465 = 'p465',
    p466 = 'p466',
    p467 = 'p467',
    p468 = 'p468',
    p469 = 'p469',
    p470 = 'p470',
    p471 = 'p471',
    p472 = 'p472',
    p473 = 'p473',
    p474 = 'p474',
    p475 = 'p475',
    p476 = 'p476',
    p477 = 'p477',
    p478 = 'p478',
    p479 = 'p479',
    p480 = 'p480',
    p481 = 'p481',
    p482 = 'p482',
    p483 = 'p483',
    p484 = 'p484',
    p485 = 'p485',
    p486 = 'p486',
    p487 = 'p487',
    p488 = 'p488',
    p489 = 'p489',
    p490 = 'p490',
    p491 = 'p491',
    p492 = 'p492',
    p493 = 'p493',
    p494 = 'p494',
    p495 = 'p495',
    p496 = 'p496',
    p497 = 'p497',
    p498 = 'p498',
    p499 = 'p499',
    p500 = 'p500',
    p501 = 'p501',
    p502 = 'p502',
    p503 = 'p503',
    p504 = 'p504',
    p505 = 'p505',
    p506 = 'p506',
    p507 = 'p507',
    p508 = 'p508',
    p509 = 'p509',
    p510 = 'p510',
    p511 = 'p511',
    p512 = 'p512',
    p513 = 'p513',
    p514 = 'p514',
    p515 = 'p515',
    p516 = 'p516',
    p517 = 'p517',
    p518 = 'p518',
    p519 = 'p519',
    p520 = 'p520',
    p521 = 'p521',
    p522 = 'p522',
    p523 = 'p523',
    p524 = 'p524',
    p525 = 'p525',
    p526 = 'p526',
    p527 = 'p527',
    p528 = 'p528',
    p529 = 'p529',
    p530 = 'p530',
    p531 = 'p531',
    p532 = 'p532',
    p533 = 'p533',
    p534 = 'p534',
    p535 = 'p535',
    p536 = 'p536',
    p537 = 'p537',
    p538 = 'p538',
    p539 = 'p539',
    p540 = 'p540',
    p541 = 'p541',
    p542 = 'p542',
    p543 = 'p543',
    p544 = 'p544',
    p545 = 'p545',
    p546 = 'p546',
    p547 = 'p547',
    p548 = 'p548',
    p549 = 'p549',
    p550 = 'p550',
    p551 = 'p551',
    p552 = 'p552',
    p553 = 'p553',
    p554 = 'p554',
    p555 = 'p555',
    p556 = 'p556',
    p557 = 'p557',
    p558 = 'p558',
    p559 = 'p559',
    p560 = 'p560',
    p561 = 'p561',
    p562 = 'p562',
    p563 = 'p563',
    p564 = 'p564',
    p565 = 'p565',
    p566 = 'p566',
    p567 = 'p567',
    p568 = 'p568',
    p569 = 'p569',
    p570 = 'p570',
    p571 = 'p571',
    p572 = 'p572',
    p573 = 'p573',
    p574 = 'p574',
    p575 = 'p575',
    p576 = 'p576',
    p577 = 'p577',
    p578 = 'p578',
    p579 = 'p579',
    p580 = 'p580',
    p581 = 'p581',
    p582 = 'p582',
    p583 = 'p583',
    p584 = 'p584',
    p585 = 'p585',
    p586 = 'p586',
    p587 = 'p587',
    p588 = 'p588',
    p589 = 'p589',
    p590 = 'p590',
    p591 = 'p591',
    p592 = 'p592',
    p593 = 'p593',
    p594 = 'p594',
    p595 = 'p595',
    p596 = 'p596',
    p597 = 'p597',
    p598 = 'p598',
    p599 = 'p599',
    p600 = 'p600',
    p601 = 'p601',
    p602 = 'p602',
    p603 = 'p603',
    p604 = 'p604',
    p605 = 'p605',
    p606 = 'p606',
    p607 = 'p607',
    p608 = 'p608',
    p609 = 'p609',
    p610 = 'p610',
    p611 = 'p611',
    p612 = 'p612',
    p613 = 'p613',
    p614 = 'p614',
    p615 = 'p615',
    p616 = 'p616',
    p617 = 'p617',
    p618 = 'p618',
    p619 = 'p619',
    p620 = 'p620',
    p621 = 'p621',
    p622 = 'p622',
    p623 = 'p623',
    p624 = 'p624',
    p625 = 'p625',
    p626 = 'p626',
    p627 = 'p627',
    p628 = 'p628',
    p629 = 'p629',
    p630 = 'p630',
    p631 = 'p631',
    p632 = 'p632',
    p633 = 'p633',
    p634 = 'p634',
    p635 = 'p635',
    p636 = 'p636',
    p637 = 'p637',
    p638 = 'p638',
    p639 = 'p639',
    p640 = 'p640',
    p641 = 'p641',
    p642 = 'p642',
    p643 = 'p643',
    p644 = 'p644',
    p645 = 'p645',
    p646 = 'p646',
    p647 = 'p647',
    p648 = 'p648',
    p649 = 'p649',
    p650 = 'p650',
    p651 = 'p651',
    p652 = 'p652',
    p653 = 'p653',
    p654 = 'p654',
    p655 = 'p655',
    p656 = 'p656',
    p657 = 'p657',
    p658 = 'p658',
    p659 = 'p659',
    p660 = 'p660',
    p661 = 'p661',
    p662 = 'p662',
    p663 = 'p663',
    p664 = 'p664',
    p665 = 'p665',
    p666 = 'p666',
    p667 = 'p667',
    p668 = 'p668',
    p669 = 'p669',
    p670 = 'p670',
    p671 = 'p671',
    p672 = 'p672',
    p673 = 'p673',
    p674 = 'p674',
    p675 = 'p675',
    p676 = 'p676',
    p677 = 'p677',
    p678 = 'p678',
    p679 = 'p679',
    p680 = 'p680',
    p681 = 'p681',
    p682 = 'p682',
    p683 = 'p683',
    p684 = 'p684',
    p685 = 'p685',
    p686 = 'p686',
    p687 = 'p687',
    p688 = 'p688',
    p689 = 'p689',
    p690 = 'p690',
    p691 = 'p691',
    p692 = 'p692',
    p693 = 'p693',
    p694 = 'p694',
    p695 = 'p695',
    p696 = 'p696',
    p697 = 'p697',
    p698 = 'p698',
    p699 = 'p699',
    p700 = 'p700',
    p701 = 'p701',
    p702 = 'p702',
    p703 = 'p703',
    p704 = 'p704',
    p705 = 'p705',
    p706 = 'p706',
    p707 = 'p707',
    p708 = 'p708',
    p709 = 'p709',
    p710 = 'p710',
    p711 = 'p711',
    p712 = 'p712',
    p713 = 'p713',
    p714 = 'p714',
    p715 = 'p715',
    p716 = 'p716',
    p717 = 'p717',
    p718 = 'p718',
    p719 = 'p719',
    p720 = 'p720',
    p721 = 'p721',
    p722 = 'p722',
    p723 = 'p723',
    p724 = 'p724',
    p725 = 'p725',
    p726 = 'p726',
    p727 = 'p727',
    p728 = 'p728',
    p729 = 'p729',
    p730 = 'p730',
    p731 = 'p731',
    p732 = 'p732',
    p733 = 'p733',
    p734 = 'p734',
    p735 = 'p735',
    p736 = 'p736',
    p737 = 'p737',
    p738 = 'p738',
    p739 = 'p739',
    p740 = 'p740',
    p741 = 'p741',
    p742 = 'p742',
    p743 = 'p743',
    p744 = 'p744',
    p745 = 'p745',
    p746 = 'p746',
    p747 = 'p747',
    p748 = 'p748',
    p749 = 'p749',
    p750 = 'p750',
    p751 = 'p751',
    p752 = 'p752',
    p753 = 'p753',
    p754 = 'p754',
    p755 = 'p755',
    p756 = 'p756',
    p757 = 'p757',
    p758 = 'p758',
    p759 = 'p759',
    p760 = 'p760',
    p761 = 'p761',
    p762 = 'p762',
    p763 = 'p763',
    p764 = 'p764',
    p765 = 'p765',
    p766 = 'p766',
    p767 = 'p767',
    p768 = 'p768',
    p769 = 'p769',
    p770 = 'p770',
    p771 = 'p771',
    p772 = 'p772',
    p773 = 'p773',
    p774 = 'p774',
    p775 = 'p775',
    p776 = 'p776',
    p777 = 'p777',
    p778 = 'p778',
    p779 = 'p779',
    p780 = 'p780',
    p781 = 'p781',
    p782 = 'p782',
    p783 = 'p783',
    p784 = 'p784',
    p785 = 'p785',
    p786 = 'p786',
    p787 = 'p787',
    p788 = 'p788',
    p789 = 'p789',
    p790 = 'p790',
    p791 = 'p791',
    p792 = 'p792',
    p793 = 'p793',
    p794 = 'p794',
    p795 = 'p795',
    p796 = 'p796',
    p797 = 'p797',
    p798 = 'p798',
    p799 = 'p799',
    p800 = 'p800',
    p801 = 'p801',
    p802 = 'p802',
    p803 = 'p803',
    p804 = 'p804',
    p805 = 'p805',
    p806 = 'p806',
    p807 = 'p807',
    p808 = 'p808',
    p809 = 'p809',
    p810 = 'p810',
    p811 = 'p811',
    p812 = 'p812',
    p813 = 'p813',
    p814 = 'p814',
    p815 = 'p815',
    p816 = 'p816',
    p817 = 'p817',
    p818 = 'p818',
    p819 = 'p819',
    p820 = 'p820',
    p821 = 'p821',
    p822 = 'p822',
    p823 = 'p823',
    p824 = 'p824',
    p825 = 'p825',
    p826 = 'p826',
    p827 = 'p827',
    p828 = 'p828',
    p829 = 'p829',
    p830 = 'p830',
    p831 = 'p831',
    p832 = 'p832',
    p833 = 'p833',
    p834 = 'p834',
    p835 = 'p835',
    p836 = 'p836',
    p837 = 'p837',
    p838 = 'p838',
    p839 = 'p839',
    p840 = 'p840',
    p841 = 'p841',
    p842 = 'p842',
    p843 = 'p843',
    p844 = 'p844',
    p845 = 'p845',
    p846 = 'p846',
    p847 = 'p847',
    p848 = 'p848',
    p849 = 'p849',
    p850 = 'p850',
    p851 = 'p851',
    p852 = 'p852',
    p853 = 'p853',
    p854 = 'p854',
    p855 = 'p855',
    p856 = 'p856',
    p857 = 'p857',
    p858 = 'p858',
    p859 = 'p859',
    p860 = 'p860',
    p861 = 'p861',
    p862 = 'p862',
    p863 = 'p863',
    p864 = 'p864',
    p865 = 'p865',
    p866 = 'p866',
    p867 = 'p867',
    p868 = 'p868',
    p869 = 'p869',
    p870 = 'p870',
    p871 = 'p871',
    p872 = 'p872',
    p873 = 'p873',
    p874 = 'p874',
    p875 = 'p875',
    p876 = 'p876',
    p877 = 'p877',
    p878 = 'p878',
    p879 = 'p879',
    p880 = 'p880',
    p881 = 'p881',
    p882 = 'p882',
    p883 = 'p883',
    p884 = 'p884',
    p885 = 'p885',
    p886 = 'p886',
    p887 = 'p887',
    p888 = 'p888',
    p889 = 'p889',
    p890 = 'p890',
    p891 = 'p891',
    p892 = 'p892',
    p893 = 'p893',
    p894 = 'p894',
    p895 = 'p895',
    p896 = 'p896',
    p897 = 'p897',
    p898 = 'p898',
    p899 = 'p899',
    p900 = 'p900',
    p901 = 'p901',
    p902 = 'p902',
    p903 = 'p903',
    p904 = 'p904',
    p905 = 'p905',
    p906 = 'p906',
    p907 = 'p907',
    p908 = 'p908',
    p909 = 'p909',
    p910 = 'p910',
    p911 = 'p911',
    p912 = 'p912',
    p913 = 'p913',
    p914 = 'p914',
    p915 = 'p915',
    p916 = 'p916',
    p917 = 'p917',
    p918 = 'p918',
    p919 = 'p919',
    p920 = 'p920',
    p921 = 'p921',
    p922 = 'p922',
    p923 = 'p923',
    p924 = 'p924',
    p925 = 'p925',
    p926 = 'p926',
    p927 = 'p927',
    p928 = 'p928',
    p929 = 'p929',
    p930 = 'p930',
    p931 = 'p931',
    p932 = 'p932',
    p933 = 'p933',
    p934 = 'p934',
    p935 = 'p935',
    p936 = 'p936',
    p937 = 'p937',
    p938 = 'p938',
    p939 = 'p939',
    p940 = 'p940',
    p941 = 'p941',
    p942 = 'p942',
    p943 = 'p943',
    p944 = 'p944',
    p945 = 'p945',
    p946 = 'p946',
    p947 = 'p947',
    p948 = 'p948',
    p949 = 'p949',
    p950 = 'p950',
    p951 = 'p951',
    p952 = 'p952',
    p953 = 'p953',
    p954 = 'p954',
    p955 = 'p955',
    p956 = 'p956',
    p957 = 'p957',
    p958 = 'p958',
    p959 = 'p959',
    p960 = 'p960',
    p961 = 'p961',
    p962 = 'p962',
    p963 = 'p963',
    p964 = 'p964',
    p965 = 'p965',
    p966 = 'p966',
    p967 = 'p967',
    p968 = 'p968',
    p969 = 'p969',
    p970 = 'p970',
    p971 = 'p971',
    p972 = 'p972',
    p973 = 'p973',
    p974 = 'p974',
    p975 = 'p975',
    p976 = 'p976',
    p977 = 'p977',
    p978 = 'p978',
    p979 = 'p979',
    p980 = 'p980',
    p981 = 'p981',
    p982 = 'p982',
    p983 = 'p983',
    p984 = 'p984',
    p985 = 'p985',
    p986 = 'p986',
    p987 = 'p987',
    p988 = 'p988',
    p989 = 'p989',
    p990 = 'p990',
    p991 = 'p991',
    p992 = 'p992',
    p993 = 'p993',
    p994 = 'p994',
    p995 = 'p995',
    p996 = 'p996',
    p997 = 'p997',
    p998 = 'p998',
    p999 = 'p999',
    p1000 = 'p1000',
    //此注释是为了自动添加平台代码进行辨认
}

export function isWeb(platform:AppPlatform) {
    return platform == AppPlatform.reader || platform == AppPlatform.p80 || platform == AppPlatform.p999;
}

export function getPlatformAliasData() {
	getPlatformAlias().then((response: any)=>{
        if(response.success) {
			let platform_alias = new Map(); //平台的别名
            if(response.data != null) {
                for (const key in response.data) {
                    platform_alias.set(key, response.data[key]);
                }
            }
			Session.set('platform_alias', Object.fromEntries(platform_alias))
            AppPlatformOptions = getAppPlatformOptions(false);
            AppPlatformOptionsAll = getAppPlatformOptions(true);
        }
    })
    .catch(()=>{
        ElMessage.error('get platform alias error, network error!');
    });
}
export function setPlatformAliasData(platform:string, value:string) {
    let v = Session.get('platform_alias')
    if(v != null && v != undefined) {
        let platform_alias = new Map(Object.entries(v))
        if(platform_alias != null && platform_alias != undefined) {
            platform_alias.set(platform, value);
        }
        Session.set('platform_alias', Object.fromEntries(platform_alias))
        AppPlatformOptions = getAppPlatformOptions(false);
        AppPlatformOptionsAll = getAppPlatformOptions(true);
    }
}

export function getPlatformAliasByName(value: string): string {
    let alias;
    let v = Session.get('platform_alias')
    if(v != null && v != undefined) {
        let platform_alias = new Map(Object.entries(v))
        alias = platform_alias == null ? "" : platform_alias.get(value);
        if(alias == undefined) {
            alias = '';
        }
    } else {
        alias = ''
    }
    return alias.toString().trim();
}


export function getAsoPlatformData() {
	getEffectivePlatform().then((response: any)=>{
        if(response.success && response.data != null) {
            Session.set('aso_platform', response.data)
            AppPlatformOptions = getAppPlatformOptions(false);
            AppPlatformOptionsAll = getAppPlatformOptions(true);
        }
    })
    .catch(()=>{
        ElMessage.error('getEffectivePlatform error, network error!');
    });
}


export function getPlatformAliasByAppPlatform(value: AppPlatform): string {
    return getPlatformAliasByName(value.toString());
}
export function getPlatformAndAliasByName(value: string): string {
    let alias = getPlatformAliasByName(value);
    return alias == '' ? value : value + "_" + alias;
}
export function getPlatformAndAliasByAppPlatform(value: AppPlatform): string {
    return getPlatformAndAliasByName(value.toString());
}

export let AppPlatformFirst = AppPlatform.cosplay;
export function getAppPlatformOptions(type:boolean) {
    const options = new Array();

    if(type) {
        options.push({value: '-1', label: 'All'});
    }

    const arr = Object.values(AppPlatform);
    let aso_platform_arr = null;
    let aso_platform_arr_str = Session.get('aso_platform')
    if(aso_platform_arr_str != null && aso_platform_arr_str != undefined) {
        aso_platform_arr = Array.from(aso_platform_arr_str);
        if(aso_platform_arr != null) {
            let app_temp
            for (const key in aso_platform_arr) {
                app_temp = getAppPlatform(aso_platform_arr[key]);
                options.push({value: app_temp, label:getPlatformAndAliasByAppPlatform(app_temp)});
            }
        }
    }

    let app;
    for (const key in arr) {
        app =  arr[key];
        if(aso_platform_arr == null || !aso_platform_arr.includes(getAppPlatformIndex(app))) {
            options.push({value: app, label:getPlatformAndAliasByAppPlatform(app)});
        }
    }
    if(!type && options.length > 0) {
        AppPlatformFirst = options[0].value;
    }
    return options;
}
export let AppPlatformOptions = getAppPlatformOptions(false);
export let AppPlatformOptionsAll = getAppPlatformOptions(true);


export function getAppPlatformByName(value: string) {
    if(value === "food" || value === "p6") {
        return AppPlatform.p6;
    } else if(value === "hikChat" || value === "p7") {
        return AppPlatform.p7;
    } else if(value === "GameTalk" || value === "p8") {
        return AppPlatform.p8;
    } else if(value === "farmers_uk") {
        return AppPlatform.farmersuk;
    }

    const arr = Object.values(AppPlatform);
    for (const key in arr) {
        if(arr[key] === value) {
            return <AppPlatform> arr[key];
        }
    }
    return null;
}


export function getAppPlatformIndexByName(value: string) : number {
    value = value.trim();
    if(value === '-1') {
        return -1;
    } else if(value === "food" || value === "p6") {
        return 6;
    } else if(value === "hikChat" || value === "p7") {
        return 7;
    } else if(value === "GameTalk" || value === "p8") {
        return 8;
    } else if(value === "farmers_uk") {
        return 2;
    }
    const arr = Object.values(AppPlatform);
    for (const key in arr) {
        if(arr[key] === value) {
            return Number(key);
        }
    }
    return 0;
}

export function getAppPlatformName(value: AppPlatform): string {
    if(value == AppPlatform.p6) {
        return "p6";
    } else if(value == AppPlatform.p7) {
        return "p7";
    } else if(value == AppPlatform.p8) {
        return "p8";
    } else {
        return value + "";
    }
}

export function getAppPlatform(value: number): AppPlatform{
    const arr = Object.values(AppPlatform);
    for (const key in arr) {
        if(Number(key) == value) {
            return <AppPlatform> arr[key];
        }
    }
    return AppPlatform.soloer;
}

export function getAppPlatformIndex(value: AppPlatform): number {
    const arr = Object.values(AppPlatform);
    for (const key in arr) {
        if(arr[key] === value) {
            return Number(key);
        }
    }
    return 0;
}

export function getAppPlatformStr(value: string) : string{
    if(value == "p6" || value === "food") {
        return "food";
    } else if(value == "p7" || value === "hikChat") {
        return "hikChat";
    } else if(value == "p8" || value === "GameTalk") {
        return "GameTalk";
    } else {
        return value;
    }
}

export function getAppPlatformEmail(value: number) {
    if(value == 3) {
        return "_cosplay";
    } else if(value == 4) {
        return "_moto";
    } else if(value == 5) {
        return "_reader";
    } else {
        return "_" + getAppPlatform(value);
    }
}