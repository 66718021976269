// 定义内容
export default {
	articlePage: {
		deleteById: '确认删除此动态? id: "{0}"',
        deleteConfirm: '确定',
        deleteCancel: '取消',
        deleteBoxTitle: '删除',
		success1: '删除成功',
        error1: '删除失败',
	},
    replyPage: {
		deleteById: '确认删除此回复? id: "{0}"',
        deleteConfirm: '确定',
        deleteCancel: '取消',
        deleteBoxTitle: '删除',
		success1: '删除成功',
        error1: '删除失败',
	},
	reviewPage: {
		deleteById: '确认删除此动态? id: "{0}"',
        deleteConfirm: '确定',
        deleteCancel: '取消',
        deleteBoxTitle: '删除',
		success1: '删除成功',
        error1: '删除失败',
		approveById: '确认通过此动态的发布? id: "{0}"',
        approveConfirm: '确定',
        approveCancel: '取消',
        approveBoxTitle: '删除',
		success2: '审核成功',
        error2: '审核失败',
	},
	reportPage: {
		deleteById: '确认删除此举报?',
        deleteConfirm: '确定',
        deleteCancel: '取消',
        deleteBoxTitle: '删除',
		success1: '删除成功',
        error1: '删除失败',
		deleteArticleById: '确认删除此动态? id: "{0}"',
        deleteArticleConfirm: '确定',
        deleteArticleCancel: '取消',
        deleteArticleBoxTitle: '删除',
	},
};
