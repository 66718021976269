// 定义内容
export default {
	affiliate: {
    totleAmount: '累计需要支付的金额:',
    username: '账号:',
    payTime:'打款时间:',
		inputUsername: '支持邮箱、账号、名称、推荐码输入',
    headCard: {
			estimated_commission: '预估收益',
			withdrawal_balance: '可提金额',
			option1_per_lead_payout: '方法一金额(完整用户资料)',
			option2_per_sale_payout: '方法二金额(每笔支付)',
    },
		tables: {
			lastName: 'lastName',
			firstName: 'firstName',
			email: '邮箱',
			phone: '电话',
			promotion_method: '推广方式',
			create_time: '注册时间',
			bank_number_in_us: '银行账号(US)',
			bank_number_out_us: '银行账号(非US)',
			paypal: 'PayPal账号',
      wise: 'Wise账号',
      IM: '聊天方式',
			estimated_commission: '预估收益',
			withdrawal_balance: '可提金额',
			operation: '操作',
      preLeadMethod:"每个完整的用户资料",
      preOrderMethod:"每笔订单",
      payment: '打款',
      summaries:'合计',
		},
    recordTables: {
			lastName: 'lastName',
			firstName: 'firstName',
			email: '邮箱',
			phone: '电话',
			withdrawal_balance: '打款金额',
			operation_time: '打款时间',
      operator:'操作员',
      summaries:'合计',
    },
		form: {
			submitBtnUpdate: '打款',
      paymenterror:"修改用户可取金额失败，请稍后重试",
      paymenterror_no_bind_card:"该推广员没有绑定银行卡",
      paymentsuccess:"打款成功",
		},
        updatePwd: {
            tablesBtn: "更新密码",
            inputSpan: "新密码",
            inputPlaceholder: "请输入新密码",
            submitBtnUpdate: "修改",
            inputPwdRule: '密码不能为空',
            updateSuccess: '更新密码成功',
            updateError: '更新密码失败',
        }
	}
};
