export default {
    systemUser: {
        btn1: '创建用户',
        btn2: '提交',
        btn3: '修改密码',
        btn4: '修改角色',
        btn5: '锁定',
        btn6: '解锁',
        btn7: '删除',
        btn8: '确定',
        btn9: '取消',
        title1: '创建用户',
        title2: '修改密码',
        title3: '修改角色',
        title4: '删除',
        table: {
            th1: '用户名',
            th2: '角色信息',
            th3: '是否锁定',
            th4: '操作',
        },
        form: {
            username: '用户名',
            password: '密码',
            confirmPassword: '确认密码',
            roleList: '角色列表',
        },
        error1: '请输入确认密码',
        error2: '确认密码不一致',
        error3: '删除用户失败',
        error4: '获得角色列表失败',
        error5: '创建用户失败',
        error6: '密码修改失败',
        error7: '操作失败',
        error8: '修改用户角色失败',
        message1: '请输入用户名',
        message2: '请输入密码',
        message3: '是',
        message4: '否',
        message5: '确定删除 {0} 用户？',
        success1: '删除用户成功',
        success2: '创建用户成功',
        success3: '密码修改成功',
        success4: '操作成功',
        success5: '修改用户角色成功',
    }
};
