// 定义内容
export default {
	home: {
		table: {
			th1: 'Date',
			th2: 'User',
			th3: 'Total',
			th4: 'Man',
			th5: 'Woman',
			th6: 'Active',
			th7: 'Verify',
			th8: 'Refund',
		},
		totalUser: 'Users',
		todayIncome: 'New Users today',
		online: 'Online',
		dailyBtn: 'Day',
		weeklyBtn: 'Week',
		monthlyBtn: 'Month',
		yearlyBtn: 'Year',
		title1: 'Register user',
		title2: 'National ranking',
		title3: 'Sales data',
		title4: 'Sales report',
		number: 'Number',
		error1: 'Failed to obtain registration data',
		error2: 'Failed to get recharge statistics',
	}
	
};
