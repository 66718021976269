// 定义内容
// ~~~~~~~~~~~~~~~~ hang ~~~~~~~~~~~~~~
export default {
    label: {
        login_label1: 'Account password',
        login_label2: 'Mobile number',
    },
    link: {
        login_link1: 'Third party login',
        login_link2: 'Links',
    },
    copyright: {
        login_copyright1: 'Copyright: CD SPD Software Technology Co., Ltd',
        login_copyright2: 'Copyright: CD SPD software technology Guangdong ICP preparation no.05010000',
    },
    account: {
        login_account_placeholder1: 'Please enter user name',
        login_account_placeholder2: 'Please enter password',
        login_account_placeholder3: 'Please enter the verification code',
        login_account_btnText: 'Sign in',
    },
    mobile: {
        login_mobile_placeholder1: 'Please input mobile phone number',
        login_mobile_placeholder2: 'Please enter the verification code',
        login_mobile_codeText: 'Get code',
        login_mobile_btnText: 'Sign in',
    },
    rule: {
        login_account_rule_msg: 'Please enter your account number',
        login_pwd_rule_msg: 'Please Enter Password',
        login_code_rule_msg: 'Please enter the verification code',
    },
    login_signInText: 'welcome back!',
    login: {
        error1: 'The user name or password is incorrect',
        error2: 'Verification code error',
        error3: 'Account locked',
        title1: 'SPD - Social Management'
    }
};
