import { Module } from 'vuex';
import { Session } from '/@/utils/storage';
import { getUserInfos } from "/@/api/user/index";
// 此处加上 `.ts` 后缀报错，具体原因不详
import { UserInfosState, RootStateTypes } from '/@/store/interface/index';

import user_head_1 from '/@/assets/images/user/head_1.png';
import user_head_2 from '/@/assets/images/user/head_2.png';

const userInfosModule: Module<UserInfosState, RootStateTypes> = {
    namespaced: true,
    state: {
        userInfos: {},
    },
    mutations: {
        // 获取用户信息
        getUserInfos(state: any, data: object) {
            state.userInfos = data;
        },
        // 设置用户信息
        setUserInfos(state: any, data: object) {
            state.userInfos = data;
            Session.set('userInfo', data);
        },
    },
    actions: {
        // 获取用户信息
        getUserInfos({ commit }, username: string) {
            return new Promise((resolve, reject) => {
                getUserInfos(username).then((result) => {
                    const { authorities, username } = result.data as any;

                    let defaultAuthPageList: Array<string> = [];
                    let defaultAuthBtnList: Array<string> = [];
                    // admin 页面权限标识，对应路由 meta.auth，用于控制路由的显示/隐藏
                    let adminAuthPageList: Array<string> = ['admin'];
                    // admin 按钮权限标识
                    let adminAuthBtnList: Array<string> = ['btn.add', 'btn.del', 'btn.edit', 'btn.link'];
                    // test 页面权限标识，对应路由 meta.auth，用于控制路由的显示/隐藏
                    let testAuthPageList: Array<string> = ['test'];
                    // test 按钮权限标识
                    let testAuthBtnList: Array<string> = ['btn.add', 'btn.link'];

                    const isSuperAdmin = authorities && authorities.indexOf('admin') != -1;
                    let photo = user_head_1;
                    // 不同用户模拟不同的用户权限
                    if (isSuperAdmin) {
                        defaultAuthPageList = adminAuthPageList;
                        defaultAuthBtnList = adminAuthBtnList;
                        photo = user_head_2;
                    } else {
                        defaultAuthPageList = testAuthPageList;
                        defaultAuthBtnList = testAuthBtnList;
                    }

                    const userInfos = {
                        userName: username,
                        photo,
                        time: new Date().getTime(),
                        authPageList: defaultAuthPageList,
                        authBtnList: defaultAuthBtnList,
                        authorities
                    };
                    commit('setUserInfos', userInfos);
                    resolve(true);
                }).catch((err) => {
                    console.log(err);
                    reject(false);
                });
            })
        },
        // 设置用户信息
        async setUserInfos({ commit }, data: object) {
            if (data) {
                commit('getUserInfos', data);
            } else {
                if (Session.get('userInfo')) commit('getUserInfos', Session.get('userInfo'));
            }
        },
    },
};

export default userInfosModule;
