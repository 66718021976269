// 定义内容
// ~~~~~~~~~~~~~~~~ hang ~~~~~~~~~~~~~~
export default {
    rolePage: {
        roleTitle: '角色管理',
        menuTitle: '菜单分配',
        menuUpdateBtn: '提交',
        tables: {
            roleName: '角色名称',
            roleCode: '角色编码',
            operation: '操作',
            add: '新增',
            sort: '排序',
            status: '状态',
        },
        radioStop: '停用',
        radioOpen: '启用',
        dialog: {
            addTitle: '新增角色',
            updateTitle: '修改角色',
            addBtn: '新增',
            updateBtn: '修改',
            cancelBtn: '取消',
        },
        deleteBoxTitle: '删除',
        deleteCodeHint: 'admin、root角色不能删除',
        deleteMsg: '确定删除 "{0}" 角色',
        deleteConfirm: '确定',
        deleteCancel: '取消',
        addRoleSucceedPrompt: '添加角色成功',
        updateRoleSucceedPrompt: '修改角色成功',
        placeholder1: '请输入角色名字',
        placeholder2: '请输入角色编码',
        placeholder3: '请选择角色',
        placeholder4: '暂无可选菜单',
        placeholder5: '数据正在加载中，不能提交...',
        placeholder6: '请选择角色',
        placeholder7: '请选择菜单',
        placeholder8: '菜单数据没有修改',
        success1: '删除角色成功',
        success2: '更新成功',
        error1: '请填写完整信息',
        error2: '删除失败，角色不存在了，请刷新',
        error3: '删除角色失败',
        error4: '获取角色的菜单信息失败',
    }
};
