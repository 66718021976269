export default {
    systemUser: {
        btn1: 'Create User',
        btn2: 'Submit',
        btn3: 'Update pwd',
        btn4: 'Update Role',
        btn5: 'Lock',
        btn6: 'Unlock',
        btn7: 'Delete',
        btn8: 'Confirm',
        btn9: 'Cancel',
        title1: 'Create User',
        title2: 'Update pwd',
        title3: 'Update Role',
        title4: 'Delete',
        table: {
            th1: 'Username',
            th2: 'Role Info',
            th3: 'Locked',
            th4: 'Operation',
        },
        form: {
            username: 'Username',
            password: 'Password',
            confirmPassword: 'Confirm Password',
            roleList: 'Role List',
        },
        error1: 'Please confirm your password',
        error2: 'The confirm password is inconsistent',
        error3: 'Failed to Delete a User',
        error4: 'Failed to get the role list',
        error5: 'Failed to create a user',
        error6: 'Password change failure',
        error7: 'Operation failure',
        error8: 'Failed to modify the user role. Procedure',
        message1: 'Please enter a user name',
        message2: 'Please enter password',
        message3: 'Yes',
        message4: 'No',
        message5: 'Confirm deleting the "{0}" user?',
        success1: 'Deleting a User Succeeded',
        success2: 'Succeeded in creating a user',
        success3: 'Password changed successfully',
        success4: 'Operate successfully',
        success5: 'User role modified successfully',
    }
};