// 定义内容
export default {
	audit: {
		man: 'Man',
		woman: 'Woman',
		other: 'Other',
		success1: 'The user audit data is updated successfully. Procedure',
		success2: 'Password updated successfully',
		success3: 'Adding a VIP succeeded',
		success4: 'Deleting a VIP succeeded',
		success5: 'Authentication processing succeeded',
		success6: 'Video duration added successfully',
		success7: 'Video duration deleted successfully',
		success8: 'Coins added successfully',
		success9: 'Coins reset successfully',
		success10: 'Update star fee successfully',
		success11: 'Update star commission successfully',
		success12: 'Update gift commission successfully',
		success13: 'Update level successfully',
		error1: 'The user name does not exist',
		error2: 'Error updating user audit data',
		error3: 'Failed to update password',
		error4: 'Failed to add a VIP  ',
		error5: 'Failed to delete a VIP  ',
		error6: 'Failed to obtain user authentication data. Procedure',
		error7: 'Failed to submit authentication',
		error8: '{0} user processing failure',
		error9: 'Failed to add video duration',
		error10: 'Failed to delete video duration',
		error11: 'Failed to add coins',
		error12: 'Failed to reset coins',
		error13: 'Failed to update star fee',
		error14: 'Failed to update star commission',
		error15: 'Failed to update gift commission',
		error16: 'Failed to update level',
		message1: 'Please enter your name',
		message2: 'Please enter a nickname',
		message3: 'Please enter gender',
		message4: 'About me',
		message5: 'User did not upload',
		message6: 'Click for a larger preview',
		message7: 'Change password ',
		message8: 'Enter the cause of the authentication failure',
		message9: 'Please enter email',
		message10: 'Signature',
		submitConfirm: {
			msg: '{0} The user does not enter the failure reason. Are you sure to submit?',
			title: 'Prompt',
			confirmBtn: 'Confirm',
			cancelBtn: 'Cancel'
		}
	}
};
