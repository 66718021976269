import { createI18n } from 'vue-i18n';
import zhcnLocale from 'element-plus/lib/locale/lang/zh-cn';
import enLocale from 'element-plus/lib/locale/lang/en';
import { store } from '/@/store/index';

import nextZhcn from '/@/i18n/lang/zh-cn';
import nextEn from '/@/i18n/lang/en';

import pagesHomeZhcn from '/@/i18n/pages/home/zh-cn';
import pagesHomeEn from '/@/i18n/pages/home/en';
import pagesLoginZhcn from '/@/i18n/pages/login/zh-cn';
import pagesLoginEn from '/@/i18n/pages/login/en';
import pagesFormI18nZhcn from '/@/i18n/pages/formI18n/zh-cn';
import pagesFormI18nEn from '/@/i18n/pages/formI18n/en';
import pagesEmailZhcn from '/@/i18n/pages/email/zh-cn';
import pagesEmailEn from '/@/i18n/pages/email/en';
import pagesGoodsEn from '/@/i18n/pages/goods/en';
import pagesGoodsZhcn from '/@/i18n/pages/goods/zh-cn';
import pagesAffiliateEn from '/@/i18n/pages/affiliate/en';
import pagesAffiliateZhcn from '/@/i18n/pages/affiliate/zh-cn';
import pagesRoleEn from '/@/i18n/pages/role/en';
import pagesRoleZhcn from '/@/i18n/pages/role/zh-cn';
import pagesSettingEn from '/@/i18n/pages/systemSetting/en';
import pagesSettingZhcn from '/@/i18n/pages/systemSetting/zh-cn';
import pagesSystemUserEn from '/@/i18n/pages/systemUser/en';
import pagesSystemUserZhcn from '/@/i18n/pages/systemUser/zh-cn';
import pagesAuditEn from '/@/i18n/pages/audit/en';
import pagesAuditZhcn from '/@/i18n/pages/audit/zh-cn';
import pagesArticleEn from '/@/i18n/pages/article/en';
import pagesArticleZhcn from '/@/i18n/pages/article/zh-cn';

// 定义语言国际化内容
/**
 * 说明：
 * /src/i18n/lang 下的 ts 为框架的国际化内容
 * /src/i18n/pages 下的 ts 为各界面的国际化内容
 */
const messages = {
    [zhcnLocale.name]: {
		...zhcnLocale,
        message: {
            ...nextZhcn,
            ...pagesHomeZhcn,
            ...pagesLoginZhcn,
            ...pagesFormI18nZhcn,
            ...pagesEmailZhcn,
            ...pagesGoodsZhcn,
            ...pagesRoleZhcn,
            ...pagesSettingZhcn,
            ...pagesSystemUserZhcn,
            ...pagesAuditZhcn,
            ...pagesAffiliateZhcn,
            ...pagesArticleZhcn,
        },
    },
    [enLocale.name]: {
		...enLocale,
        message: {
            ...nextEn,
            ...pagesHomeEn,
            ...pagesLoginEn,
            ...pagesFormI18nEn,
            ...pagesEmailEn,
            ...pagesGoodsEn,
            ...pagesRoleEn,
            ...pagesSettingEn,
            ...pagesSystemUserEn,
            ...pagesAuditEn,
            ...pagesAffiliateEn,
            ...pagesArticleEn,
        },
    }
};

// 导出语言国际化
export const i18n = createI18n({
    locale: store.state.themeConfig.themeConfig.globalI18n,
    fallbackLocale: zhcnLocale.name,
    messages,
});
