// 定义内容
// ~~~~~~~~~~~~~~~~ hang ~~~~~~~~~~~~~~
export default {
    setting: {
        btn1: 'Refresh cache',
        success1: 'Cache refresh success',
        error1: 'Cache refresh failed',
        error2: ' Description The interface failed to refresh the cache',
    }
};
