// 定义内容
// ~~~~~~~~~~~~~~~~ hang ~~~~~~~~~~~~~~
export default {
    label: {
        login_label1: '账号密码登录',
        login_label2: '手机号登录',
    },
    link: {
        login_link1: '第三方登录',
        login_link2: '友情链接',
    },
    copyright: {
        login_copyright1: '版权所有：CD.SPD软件科技有限公司',
        login_copyright2: 'Copyright: CD SPD Software Technology 粤ICP备05010000号',
    },
    account: {
        login_account_placeholder1: '请输入用户名',
        login_account_placeholder2: '请输入密码',
        login_account_placeholder3: '请输入验证码',
        login_account_btnText: '登 录',
    },
    mobile: {
        login_mobile_placeholder1: '请输入手机号',
        login_mobile_placeholder2: '请输入验证码',
        login_mobile_codeText: '获取验证码',
        login_mobile_btnText: '登 录',
    },
    rule: {
        login_account_rule_msg: '请输入账号',
        login_pwd_rule_msg: '请输入密码',
        login_code_rule_msg: '请输入验证码',
    },
    login_signInText: '欢迎回来！',
    login: {
        error1: '用户名或密码错误',
        error2: '验证码错误',
        error3: '账号被锁定了',
        title1: 'SPD-社交后台管理'
    }
};
