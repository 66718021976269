import request from '/@/utils/request';
import qs from "qs";

// ~~~~~~~~~~~~~~~~ hang ~~~~~~~~~~~~~~
/**
 * 获取用户
 * @param username 要传的参数值
 * @returns 返回接口数据
 */
export function getUserInfos(username: string) {
    return request({
        url: '/admin/user/getUserInfo?username=' + username,
        method: 'post'
    });
}

export function getBadges(params: object) {
    return request({
        url: '/admin/getBadges?names=' + params,
        method: 'get',
    });
}

/**
 * 获得用户列表
 * 
 * @param params 
 * @returns 
 */
export function getUserList(params: Object) {
    return request({
        url: '/admin/user/getUserList',
        method: 'get',
        params
    });
}
/**
 * 创建账号
 * @param params 
 * @returns 
 */
export function createBackAccount(params: Object) {
    return request({
        url: '/admin/user/createBackAccount',
        method: 'put',
        data: qs.parse(params)
    });
}
/**
 * 重置密码
 * @param params 
 * @returns 
 */
export function resetPassword(params: Object) {
    return request({
        url: '/admin/user/resetPassword',
        method: 'put',
        params
    });
}
/**
 * 重置昵称
 * @param params 
 * @returns 
 */
export function resetNickname(params: Object) {
    return request({
        url: '/admin/user/resetNickname',
        method: 'put',
        params
    });
}
/**
 * 删除账号
 * @param username 
 * @returns 
 */
export function deleteAccount(username: string) {
    return request({
        url: '/admin/user/deleteAccount?username=' + username,
        method: 'delete',
    });
}
/**
 * 更新用户状态
 * @param username 
 * @returns 
 */
export function updateUserStatus(params?: Object) {
    return request({
        url: '/admin/user/updateUserStatus',
        method: 'put',
        params,
    });
}
/**
 * 修改用户的角色
 * @param params 
 * @returns 
 */
export function updateSnsUserRole(params?: Object) {
    return request({
        url: '/admin/user/updateSnsUserRole',
        method: 'put',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(params, { indices: false })
    });
}
/**
 * 配置用户的访问平台
 * @param params 
 * @returns 
 */
export function updateSettingUserPlatform(params?: Object) {
    return request({
        url: '/admin/user/setting_user_platform',
        method: 'put',
        data: qs.parse(params)
    });
}
/**
 * 获取配置用户的访问平台
 * @param params 
 * @returns 
 */
export function getSettingUserPlatform(params?: Object) {
    return request({
        url: '/admin/user/setting_user_platform',
        method: 'get',
        params
    });
}

