// 定义内容
export default {
	email: {
		markRead: "标记已读",
		emailStatueAll: "全部",
		emailStatueRead: "已读",
		emailStatueUnread: "未读",
		emailReply: '回复',
		emailTitle: '邮件详情',
		send: {
			addressee: '收件人',
			sender: '发件人',
			time: '时间',
			subject: '主题',
			content: '邮件内容',
			sendBtn: '发送',
			cancelBtn: '取消',
			emailPrompt: '请输入邮箱地址',
			contentPrompt: '请输入邮件内容',
			emailSendSucceed: "邮件发送成功",
			emailSendError: "邮件发送失败",
			emailFormatError: '请输入正确的邮件地址',
		},
	}
};
