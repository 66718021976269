import request from '/@/utils/request';
import qs from "qs";

// ~~~~~~~~~~~~~~~~ hang ~~~~~~~~~~~~~~
/**
 * 获取新的审核数据
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function getNewProfiles(params?: object) {
    return request({
        url: '/admin/audit/getNewProfiles',
        method: 'get',
        params
    });
}

/**
 * 修改新的审核数据
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function updateNewProfiles(params: object) {
    return request({
        url: '/admin/audit/updateNewProfiles',
        method: 'put',
        data: qs.parse({ arr: params })
    });
}
/**
 * 获取新的主播审核数据
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function getNewStarProfiles(params?: object) {
    return request({
        url: '/admin/audit/getNewStarProfiles',
        method: 'get',
        params
    });
}

/**
 * 修改新的主播审核数据
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function updateNewStarProfiles(params: object) {
    return request({
        url: '/admin/audit/updateNewStarProfiles',
        method: 'put',
        data: qs.parse({ arr: params })
    });
}

/**
 * 获取旧的审核数据(有修改的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function getOldProfiles(params?: object) {
    return request({
        url: '/admin/audit/getOldProfiles',
        method: 'get',
        params
    });
}

/**
 * 修改旧的审核数据(有修改的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function updateOldProfiles(params: object) {
    return request({
        url: '/admin/audit/updateOldProfiles',
        method: 'put',
        data: qs.parse({ arr: params })
    });
}

/**
 * 获取支付的审核数据(购买商品的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function getPremiumProfile(params?: object) {
    return request({
        url: '/admin/audit/getPremiumProfile',
        method: 'get',
        params
    });
}

/**
 * 修改支付的审核数据(购买商品的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function updatePremiumProfiles(params: object) {
    return request({
        url: '/admin/audit/updatePremiumProfiles',
        method: 'put',
        data: qs.parse({ arr: params })
    });
}

/**
 * 获取视频的审核数据(购买视频的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function getUserVideoProfile(params?: object) {
    return request({
        url: '/admin/audit/getUserVideoProfile',
        method: 'get',
        params
    });
}

/**
 * 修改视频的审核数据(购买视频的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function updateUserVideoProfile(params: object) {
    return request({
        url: '/admin/audit/updateUserVideoProfile',
        method: 'put',
        data: qs.parse({ arr: params })
    });
}

/**
 * 获取视频的审核数据(被举报的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function getReportUserProfile(params?: object) {
    return request({
        url: '/admin/audit/getReportUserProfile',
        method: 'get',
        params
    });
}

/**
 * 获取通话评价的数据
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function getCallCommentsUserProfile(params?: object) {
    return request({
        url: '/admin/audit/getCallCommentsUserProfile',
        method: 'get',
        params
    });
}

/**
 * 获取举报数据
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function getReportData(params?: object) {
    return request({
        url: '/admin/audit/getReportData',
        method: 'get',
        params
    });
}

/**
 * 获取通话评价数据
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function getCallComments(params?: object) {
    return request({
        url: '/admin/wss/getCallComments',
        method: 'get',
        params
    });
}

/**
 * 修改视频的审核数据(被举报的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function updateReportUserProfile(params: object) {
    return request({
        url: '/admin/audit/updateReportUserProfile',
        method: 'put',
        data: qs.parse({ arr: params })
    });
}
/**
 * 修改视频的通话评价数据
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function updateCallCommentsUserProfile(params: object) {
    return request({
        url: '/admin/audit/updateCallCommentsUserProfile',
        method: 'put',
        data: qs.parse({ arr: params })
    });
}

/**
 * 查询用户审核数据(搜索的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function searchProfilesByName(params: object) {
    return request({
        url: '/admin/audit/serachUser',
        method: 'get',
        params
    });
}

/**
 * 条件查询
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function searchConditional(params: object) {
    return request({
        url: '/admin/audit/searchConditional',
        method: 'get',
        params
    });
}
/**
 * 查询用户审核数据(搜索的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function searchProfilesByName2(params: object) {
    return request({
        url: '/admin/audit/serachUser2',
        method: 'get',
        params
    });
}
/**
 * 查询用户审核数据(搜索的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function searchTags(params: object) {
    return request({
        url: '/admin/audit/serachTags',
        method: 'get',
        params
    });
}
/**
 * 查询用户审核数据(搜索的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function searchRealUser(params: object) {
    return request({
        url: '/admin/audit/searchRealUser',
        method: 'get',
        params
    });
}

/**
 * 更新用户审核数据(搜索的审核数据)
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function updateProfilesByName(params: object) {
    return request({
        url: '/admin/audit/updateSingleUserProfile',
        method: 'put',
        data: qs.parse(params)
    });
}

/**
 * 获取用户认证数据
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function getUserVerityArr(params?: object) {
    return request({
        url: '/admin/audit/getUserVerityArr',
        method: 'get',
        params
    });
}

/**
 * 修改用户认证处理
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function review(params: object) {
    return request({
        url: '/admin/audit/review',
        method: 'put',
        data: qs.parse(params)
    });
}

/**
 * 修改用户密码
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function updateUserPassword(params: object) {
    return request({
        url: '/admin/user/resetPassword',
        method: 'put',
        params
    });
}

/**
 * 添加VIP
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function addVip(params: object) {
    return request({
        url: '/admin/audit/renewVip',
        method: 'put',
        params
    });
}

/**
 * 删除VIP
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function deleteVip(params: object) {
    return request({
        url: '/admin/audit/deleteVip',
        method: 'delete',
        params
    });
}
/**
 * 获得可以翻页的用户信息
 * @param params 
 * @returns 
 */
export function getUserProfileDtoPage(params: object) {
    return request({
        url: '/admin/audit/getUserProfileDtoPage',
        method: 'post',
        params
    });
}
/**
 * 获取agent的用户列表
 * @param params 
 * @returns 
 */
export function getUserProfileDtoByAgent(params: object) {
    return request({
        url: '/admin/audit/getUserProfileDtoByAgent',
        method: 'get',
        params
    });
}

/**
 * 修改用户信息
 * @param params 
 * @returns 
 */
export function updateUserProfiles(params: object) {
    return request({
        url: '/admin/audit/updateUserProfiles',
        method: 'put',
        data: qs.parse({ arr: params })
    });
}

/**
 * 修改用户信息
 * @param params 
 * @returns 
 */
export function refreshUserCache(params: object) {
    return request({
        url: '/admin/audit/refreshUserCache',
        method: 'delete',
        params
    });
}

/**
 * 更新用户视频时长
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function updateVideoTime(params: object) {
    return request({
        url: '/admin/audit/updateVideoTime',
        method: 'put',
        params
    });
}

/**
 * 删除用户视频时长
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function deleteVideoTime(params: object) {
    return request({
        url: '/admin/audit/deleteVideoTime',
        method: 'put',
        params
    });
}
 export function addUserStar(params: object) {
    return request({
        url: '/admin/audit/addUserStar',
        method: 'put',
        params
    });
}
 export function delUserStar(params: object) {
    return request({
        url: '/admin/audit/delUserStar',
        method: 'put',
        params
    });
}



/**
 * 更新用户金币
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function updateCoins(params: object) {
    return request({
        url: '/admin/audit/updateUserCoins',
        method: 'put',
        params
    });
}

/**
 * 更新用户充值状态
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function updateRecharge(params: object) {
    return request({
        url: '/admin/audit/updateRecharge',
        method: 'put',
        params
    });
}

/**
 * 重置用户金币
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function resetCoins(params: object) {
    return request({
        url: '/admin/audit/resetUserCoins',
        method: 'delete',
        params
    });
}


/**
 * 更新主播收费
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function updateUserStarFee(params: object) {
    return request({
        url: '/admin/audit/updateUserStarFee',
        method: 'put',
        params
    });
}

/**
 * 更新主播提成
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function updateUserStarCommission(params: object) {
    return request({
        url: '/admin/audit/updateUserStarCommission',
        method: 'put',
        params
    });
}
/**
 * 更新主播礼物提成
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function updateUserGiftCommission(params: object) {
    return request({
        url: '/admin/audit/updateUserGiftCommission',
        method: 'put',
        params
    });
}
/**
 * 更新主播玩具提成
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function updateUserToyCommission(params: object) {
    return request({
        url: '/admin/audit/updateUserToyCommission',
        method: 'put',
        params
    });
}

/**
 * 更新主播提成配置
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function updateUserStarCommissionConfig(params: object) {
    return request({
        url: '/admin/audit/updateUserStarCommissionConfig',
        method: 'put',
        params
    });
}
/**
 * 更新所有主播提成配置
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function updateUserStarCommissionConfigAll(params: object) {
    return request({
        url: '/admin/audit/updateUserStarCommissionConfigAll',
        method: 'put',
        params
    });
}

/**
 * 更新用户等级
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function updateUserLevel(params: object) {
    return request({
        url: '/admin/audit/updateUserLevel',
        method: 'put',
        params
    });
}
/**
 * 更新用户代理
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function bingAgent(params: object) {
    return request({
        url: '/admin/agent/bing',
        method: 'put',
        params
    });
}

/**
 * 增加虚拟主播
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function registerAnchor(params: object) {
    return request({
        url: '/admin/anchor/registerAnchor',
        method: 'put',
        data: qs.parse(params)
    });
}



/**
 * 获取代理列表
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
 export function getAgentArr(params?: object) {
    return request({
        url: '/admin/agent/getAgentArr',
        method: 'get',
        params
    });
}


/**
 * 增加主播备注
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function addStarComment(params: object) {
    return request({
        url: '/admin/audit/addStarComment',
        method: 'put',
        data: qs.parse(params)
    });
}

/**
 * 删除主播备注
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function deleteStarComment(params: object) {
    return request({
        url: '/admin/audit/deleteStarComment',
        method: 'delete',
        params
    });
}

/**
 * 获取主播备注
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function getStarComment(params: object) {
    return request({
        url: '/admin/audit/getStarComment',
        method: 'get',
        params
    });
}



/**
 * 设置主播是否在web可见
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function setStarWebVisibility(params: object) {
    return request({
        url: '/admin/audit/setStarWebVisibility',
        method: 'put',
        params
    });
}

/**
 * 更新主播的标签
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
export function updateStarTags(params: object) {
    return request({
        url: '/admin/audit/updateStarTags',
        method: 'put',
        data: qs.parse(params)
    });
}
